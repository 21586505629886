// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import { retrieveSustainabilityStandards } from './operations';

// Types
import { AppState } from '../../reducers';
import { SustainabilityStandardMMBrain } from './interfaces';

// Redux
import {
  doRetrieveSustainabilityStandards,
  doRetrieveSustainabilityStandardsFailure,
  doRetrieveSustainabilityStandardsSuccess
} from './actions';
import { RETRIEVE_SUSTAINABILITY_STANDARDS } from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { workerRefreshTokenAndRetry } from '../auth/sagas';

function* workerRetrieveSustainabilityStandards() {
  try {
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<SustainabilityStandardMMBrain[]> = yield call(
        retrieveSustainabilityStandards,
        profile.accessToken
      );
      if (response.data) {
        yield put(doRetrieveSustainabilityStandardsSuccess(response.data));
      } else {
        yield put(doRetrieveSustainabilityStandardsFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSustainabilityStandards()));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doRetrieveSustainabilityStandards()));
    } else {
      logging.error(error);
      yield put(doRetrieveSustainabilityStandardsFailure());
    }
  }
}

export function* watcherRetrieveSustainabilityStandards() {
  yield takeLatest(RETRIEVE_SUSTAINABILITY_STANDARDS, workerRetrieveSustainabilityStandards);
}

export function* sustainabilityStandardsSaga() {
  yield all([call(watcherRetrieveSustainabilityStandards)]);
}
