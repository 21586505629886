import {
  RETRIEVE_BUSINESS_OPPORTUNITIES,
  RETRIEVE_BUSINESS_OPPORTUNITIES_SUCCESS,
  RETRIEVE_BUSINESS_OPPORTUNITIES_FAILURE,
  RetrieveBusinessOpportunitiesAction,
  RetrieveBusinessOpportunitiesSuccessAction,
  RetrieveBusinessOpportunitiesFailureAction,
  RETRIEVE_BUSINESS_OPPORTUNITIES_STATS,
  RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_SUCCESS,
  RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_FAILURE,
  RetrieveBusinessOpportunitiesStatsAction,
  RetrieveBusinessOpportunitiesStatsSuccessAction,
  RetrieveBusinessOpportunitiesStatsFailureAction,
  CREATE_BUSINESS_OPPORTUNITY,
  CREATE_BUSINESS_OPPORTUNITY_SUCCESS,
  CREATE_BUSINESS_OPPORTUNITY_FAILURE,
  CreateBusinessOpportunityAction,
  CreateBusinessOpportunitySuccessAction,
  CreateBusinessOpportunityFailureAction,
  UPDATE_BUSINESS_OPPORTUNITY,
  UPDATE_BUSINESS_OPPORTUNITY_SUCCESS,
  UPDATE_BUSINESS_OPPORTUNITY_FAILURE,
  UpdateBusinessOpportunityAction,
  UpdateBusinessOpportunitySuccessAction,
  UpdateBusinessOpportunityFailureAction,
  DELETE_BUSINESS_OPPORTUNITY,
  DELETE_BUSINESS_OPPORTUNITY_SUCCESS,
  DELETE_BUSINESS_OPPORTUNITY_FAILURE,
  DeleteBusinessOpportunityAction,
  DeleteBusinessOpportunitySuccessAction,
  DeleteBusinessOpportunityFailureAction,
  DO_CLEAR_LAST_OPERATION_INFORMATION,
  RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES,
  RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_SUCCESS,
  RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_FAILURE,
  RetrieveManualBusinessOpportunitiesAction,
  RetrieveManualBusinessOpportunitiesSuccessAction,
  RetrieveManualBusinessOpportunitiesFailureAction,
  UPDATE_BUSINESS_OPPORTUNITY_NEW_PROGRESS,
  UpdateBusinessOpportunityNewProgressAction
} from './types';
import {
  BusinessOpportunitiesResultsAggregationsMMBrain,
  BusinessOpportunityMMBrain,
  BusinessOpportunityModificationData,
  BusinessOpportunityModificationResponseData,
  BusinessOpportunityOperation,
  ManualBusinessOpportunityMMBrain,
  RetrieveBusinessOpportunitiesFilter
} from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Retrieve BOs for overview
export const doRetrieveBusinessOpportunities = (
  filter: RetrieveBusinessOpportunitiesFilter
): RetrieveBusinessOpportunitiesAction => ({
  type: RETRIEVE_BUSINESS_OPPORTUNITIES,
  filter
});

export const doRetrieveBusinessOpportunitiesFailure = (
  error?: RequestResult<void>
): RetrieveBusinessOpportunitiesFailureAction => ({
  error,
  type: RETRIEVE_BUSINESS_OPPORTUNITIES_FAILURE
});

export const doRetrieveBusinessOpportunitiesSuccess = (
  opportunities: BusinessOpportunityMMBrain[],
  aggregations: BusinessOpportunitiesResultsAggregationsMMBrain,
  total: number
): RetrieveBusinessOpportunitiesSuccessAction => ({
  type: RETRIEVE_BUSINESS_OPPORTUNITIES_SUCCESS,
  opportunities,
  aggregations,
  total
});

// Retrieve BOs for dashboard
export const doRetrieveManualBusinessOpportunities = (
  filter: RetrieveBusinessOpportunitiesFilter
): RetrieveManualBusinessOpportunitiesAction => ({
  type: RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES,
  filter
});

export const doRetrieveManualBusinessOpportunitiesFailure = (
  error?: RequestResult<void>
): RetrieveManualBusinessOpportunitiesFailureAction => ({
  error,
  type: RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_FAILURE
});

export const doRetrieveManualBusinessOpportunitiesSuccess = (
  opportunities: ManualBusinessOpportunityMMBrain[],
  total: number
): RetrieveManualBusinessOpportunitiesSuccessAction => ({
  type: RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_SUCCESS,
  opportunities,
  total
});

//Stats
export const doRetrieveBusinessOpportunitiesStats = (
  keywords: string,
  countryGuids: string[],
  businessTypeGuids: string[],
  industryGuids: string[],
  typesOfWorkGuids: string[],
  isSustainable?: boolean,
  internalSource?: string
): RetrieveBusinessOpportunitiesStatsAction => {
  return {
    type: RETRIEVE_BUSINESS_OPPORTUNITIES_STATS,
    keywords,
    countryGuids,
    businessTypeGuids,
    industryGuids,
    typesOfWorkGuids,
    isSustainable,
    internalSource
  };
};

export const doRetrieveBusinessOpportunitiesStatsFailure = (
  error?: RequestResult<void>
): RetrieveBusinessOpportunitiesStatsFailureAction => ({
  error,
  type: RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_FAILURE
});

export const doRetrieveBusinessOpportunitiesStatsSuccess = (
  aggregations: BusinessOpportunitiesResultsAggregationsMMBrain
): RetrieveBusinessOpportunitiesStatsSuccessAction => ({
  type: RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_SUCCESS,
  aggregations
});

// Create business opportunity
export const doCreateBusinessOpportunity = (
  data: BusinessOpportunityModificationData,
  onUploadProgress?: (uploadEvent: any) => void
): CreateBusinessOpportunityAction => ({
  type: CREATE_BUSINESS_OPPORTUNITY,
  data,
  onUploadProgress
});

export const doCreateBusinessOpportunitySuccess = (
  businessOpportunityModificationResponseData: BusinessOpportunityModificationResponseData
): CreateBusinessOpportunitySuccessAction => ({
  type: CREATE_BUSINESS_OPPORTUNITY_SUCCESS,
  businessOpportunityModificationResponseData
});

export const doCreateBusinessOpportunityFailure = (
  error?: RequestResult<void>
): CreateBusinessOpportunityFailureAction => ({
  type: CREATE_BUSINESS_OPPORTUNITY_FAILURE,
  error
});

// Update business opportunity
export const doUpdateBusinessOpportunity = (
  id: string,
  data: Partial<BusinessOpportunityModificationData>,
  operationName?: BusinessOpportunityOperation,
  updateStatusOnly?: boolean,
  onUploadProgress?: (uploadEvent: any) => void
): UpdateBusinessOpportunityAction => ({
  type: UPDATE_BUSINESS_OPPORTUNITY,
  data,
  id,
  operation: operationName,
  updateStatusOnly,
  onUploadProgress
});

export const doUpdateBusinessOpportunitySuccess = (
  businessOpportunityModificationResponseData: BusinessOpportunityModificationResponseData
): UpdateBusinessOpportunitySuccessAction => ({
  type: UPDATE_BUSINESS_OPPORTUNITY_SUCCESS,
  businessOpportunityModificationResponseData
});

export const doUpdateBusinessOpportunityFailure = (
  error?: RequestResult<void>
): UpdateBusinessOpportunityFailureAction => ({
  type: UPDATE_BUSINESS_OPPORTUNITY_FAILURE,
  error
});

export const doDeleteBusinessOpportunity = (id: string, reason?: string): DeleteBusinessOpportunityAction => ({
  type: DELETE_BUSINESS_OPPORTUNITY,
  id,
  reason
});

// Delete business opportunity
export const doUpdateBusinessOpportunityNewProgress = (
  normalizedProgress?: number
): UpdateBusinessOpportunityNewProgressAction => ({
  type: UPDATE_BUSINESS_OPPORTUNITY_NEW_PROGRESS,
  normalizedProgress
});

export const doDeleteBusinessOpportunitySuccess = (): DeleteBusinessOpportunitySuccessAction => ({
  type: DELETE_BUSINESS_OPPORTUNITY_SUCCESS
});

export const doDeleteBusinessOpportunityFailure = (
  error?: RequestResult<void>
): DeleteBusinessOpportunityFailureAction => ({
  type: DELETE_BUSINESS_OPPORTUNITY_FAILURE,
  error
});

export const doClearLastOperationInformation = () => ({
  type: DO_CLEAR_LAST_OPERATION_INFORMATION
});
