import axios from 'axios';
import getConfig from 'next/config';
import { AddWatchlistCompanyDto, SupsWatchlistCompanyRaw, UpdateWatchlistCompanyDto } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrieveWatchlistCompanies(accessToken: string, locale: string, watchlistId: number) {
  return axios.get<RequestResult<SupsWatchlistCompanyRaw[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/companies`,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export function addWatchlistCompany(
  accessToken: string,
  locale: string,
  watchlistId: number,
  data: AddWatchlistCompanyDto
) {
  return axios.post<RequestResult<SupsWatchlistCompanyRaw>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/companies`,
    data,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export function updateWatchlistCompany(
  accessToken: string,
  locale: string,
  watchlistId: number,
  companyId: number,
  data: UpdateWatchlistCompanyDto
) {
  return axios.put<RequestResult<SupsWatchlistCompanyRaw>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/companies/${companyId}`,
    data,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export function removeWatchlistCompany(accessToken: string, locale: string, watchlistId: number, companyId: number) {
  return axios.delete<RequestResult<SupsWatchlistCompanyRaw>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/companies/${companyId}`,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export function removeWatchlistCompanies(
  accessToken: string,
  locale: string,
  watchlistId: number,
  companyIds: number[]
) {
  return axios.delete<RequestResult<SupsWatchlistCompanyRaw[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}/companies`,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' },
      data: {
        companyIds
      }
    }
  );
}
