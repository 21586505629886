import axios from 'axios';
import getConfig from 'next/config';
import {
  CreateSupsWatchlistDto,
  RetrieveSupsWatchlistParams,
  SupsWatchlistDetails,
  SupsWatchlistPage,
  SupsWatchlistRaw,
  UpdateSupsWatchlistDto
} from './interfaces';
import { RequestResult } from '../../../interfaces/requests';
import { SupsWatchlistCompanyRaw } from '@redux-modules/watchlistCompanies/interfaces';
import { exportToExcel } from '@utils/excelUtils';
import { companySizeLabelMap } from '@modules/supplier-sourcing/common/helpers';

const { publicRuntimeConfig } = getConfig();

export function retrieveWatchlistsPaginated(accessToken: string, locale: string, params?: RetrieveSupsWatchlistParams) {
  return axios.get<RequestResult<SupsWatchlistPage[]>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/paginated`,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' },
      params
    }
  );
}

export function retrieveWatchlists(accessToken: string, locale: string, params?: RetrieveSupsWatchlistParams) {
  return axios.get<RequestResult<SupsWatchlistRaw[]>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists`, {
    headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' },
    params
  });
}

export function getWatchlistDetails(accessToken: string, locale: string, watchlistId: number) {
  return axios.get<RequestResult<SupsWatchlistDetails>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}`,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export async function exportWatchlist(
  accessToken: string,
  locale: string,
  watchlistId: number,
  watchlistDetails?: SupsWatchlistDetails
) {
  let watchlist = watchlistDetails;
  if (!watchlistDetails) {
    const {
      data: { result }
    } = await getWatchlistDetails(accessToken, locale, watchlistId);
    watchlist = result;
  }
  if (watchlist) {
    exportToExcel(
      watchlist.companies.map(company => {
        const website = (company?.website_resolved_domains || company?.website_start_domains)?.[0];
        const interestedValue = company.isInterested === null ? '' : company.isInterested ? 'yes' : 'no';
        return {
          Company: company.name,
          Size: companySizeLabelMap[company.companySize] || company.companySize,
          Website: website ? `https://${website}` : '',
          'Contact Details': company.features ? company.features.join(', ') : '',
          Contacted: company.isContacted ? 'yes' : 'no',
          Interested: interestedValue
        };
      }),
      watchlist.name
    );
  }
  return true;
}

export function createWatchlist(accessToken: string, locale: string, data: CreateSupsWatchlistDto) {
  return axios.post<RequestResult<SupsWatchlistRaw>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists`, data, {
    headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
  });
}

export function duplicateWatchlist(
  accessToken: string,
  locale: string,
  watchlistId: number,
  data: CreateSupsWatchlistDto
) {
  return axios.post<RequestResult<SupsWatchlistRaw>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}`,
    data,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export function updateWatchlist(
  accessToken: string,
  locale: string,
  watchlistId: number,
  data: UpdateSupsWatchlistDto
) {
  return axios.patch<RequestResult<SupsWatchlistRaw>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}`,
    data,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}

export function deleteWatchlist(accessToken: string, locale: string, watchlistId: number) {
  return axios.delete<RequestResult<SupsWatchlistCompanyRaw>>(
    `${publicRuntimeConfig.API_URI}/v1/${locale}/watchlists/${watchlistId}`,
    {
      headers: { Authorization: `Bearer ${accessToken}`, accept: 'application/json' }
    }
  );
}
