import { SustainabilityStandardMMBrain } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_SUSTAINABILITY_STANDARDS = 'sustainabilityStandards/RETRIEVE_SUSTAINABILITY_STANDARDS';
export const RETRIEVE_SUSTAINABILITY_STANDARDS_SUCCESS =
  'sustainabilityStandards/RETRIEVE_SUSTAINABILITY_STANDARDS_SUCCESS';
export const RETRIEVE_SUSTAINABILITY_STANDARDS_FAILURE =
  'sustainabilityStandards/RETRIEVE_SUSTAINABILITY_STANDARDS_FAILURE';

export interface RetrieveSustainabilityStandardsAction {
  type: typeof RETRIEVE_SUSTAINABILITY_STANDARDS;
}

export interface RetrieveSustainabilityStandardsSuccessAction {
  type: typeof RETRIEVE_SUSTAINABILITY_STANDARDS_SUCCESS;
  sustainabilityStandards: SustainabilityStandardMMBrain[];
}

export interface RetrieveSustainabilityStandardsFailureAction {
  type: typeof RETRIEVE_SUSTAINABILITY_STANDARDS_FAILURE;
  error?: RequestResult<void>;
}
