// Modules
import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import logging from '../../../logging';
import { AxiosResponse } from 'axios';

// Operations(API Calls)
import {
  createBusinessOpportunity,
  deleteBusinessOpportunity,
  retrieveBusinessOpportunities,
  retrieveBusinessOpportunitiesStats,
  updateBusinessOpportunity
} from './operations';

// Types
import { AppState } from '../../reducers';
import { BusinessOpportunitiesMMBrain, BusinessOpportunityModificationResponseData } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

// Redux
import {
  doRetrieveBusinessOpportunities,
  doRetrieveBusinessOpportunitiesFailure,
  doRetrieveBusinessOpportunitiesStats,
  doRetrieveBusinessOpportunitiesStatsFailure,
  doRetrieveBusinessOpportunitiesStatsSuccess,
  doRetrieveBusinessOpportunitiesSuccess,
  doCreateBusinessOpportunity,
  doCreateBusinessOpportunityFailure,
  doCreateBusinessOpportunitySuccess,
  doUpdateBusinessOpportunity,
  doUpdateBusinessOpportunityFailure,
  doUpdateBusinessOpportunitySuccess,
  doDeleteBusinessOpportunitySuccess,
  doDeleteBusinessOpportunityFailure,
  doDeleteBusinessOpportunity,
  doRetrieveManualBusinessOpportunities,
  doRetrieveManualBusinessOpportunitiesSuccess,
  doRetrieveManualBusinessOpportunitiesFailure
} from './actions';
import {
  RETRIEVE_BUSINESS_OPPORTUNITIES,
  RetrieveBusinessOpportunitiesAction,
  RETRIEVE_BUSINESS_OPPORTUNITIES_STATS,
  RetrieveBusinessOpportunitiesStatsAction,
  CREATE_BUSINESS_OPPORTUNITY,
  CREATE_BUSINESS_OPPORTUNITY_FAILURE,
  CreateBusinessOpportunityAction,
  UPDATE_BUSINESS_OPPORTUNITY,
  UPDATE_BUSINESS_OPPORTUNITY_FAILURE,
  UpdateBusinessOpportunityAction,
  DELETE_BUSINESS_OPPORTUNITY,
  DELETE_BUSINESS_OPPORTUNITY_FAILURE,
  DeleteBusinessOpportunityAction,
  RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES,
  RetrieveManualBusinessOpportunitiesAction
} from './types';
import { doRefreshTokenAndRetry } from '../auth/actions';
import { AuthenticatedProfile } from '../auth/interfaces';
import { parseError } from '../../../utils/validationUtils';
import { workerRefreshTokenAndRetry } from '@redux-modules/auth/sagas';
import { BoInternalSource } from 'src/modules/business-opportunities/interfaces';

function* workerUpdateBusinessOpportunity({
  id,
  data,
  updateStatusOnly,
  onUploadProgress
}: UpdateBusinessOpportunityAction) {
  try {
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<BusinessOpportunityModificationResponseData> = yield call(
        updateBusinessOpportunity,
        {
          data,
          id,
          accessToken: profile.accessToken,
          updateStatusOnly,
          onUploadProgress
        }
      );
      if (response.data) {
        yield put(doUpdateBusinessOpportunitySuccess(response.data));
      } else {
        yield put(doUpdateBusinessOpportunityFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doUpdateBusinessOpportunity(id, data)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error?.response?.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doUpdateBusinessOpportunity(id, data)));
    } else {
      logging.error(error);
      yield put({
        type: UPDATE_BUSINESS_OPPORTUNITY_FAILURE,
        error
      });
    }
  }
}

export function* watcherUpdateBusinessOpportunity() {
  yield takeLatest(UPDATE_BUSINESS_OPPORTUNITY, workerUpdateBusinessOpportunity);
}

function* workerDeleteBusinessOpportunity({ id, reason }: DeleteBusinessOpportunityAction) {
  try {
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse = yield call(deleteBusinessOpportunity, {
        id,
        accessToken: profile.accessToken,
        reason
      });
      if (response.status === 200) {
        yield put(doDeleteBusinessOpportunitySuccess());
      } else {
        yield put(doDeleteBusinessOpportunityFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doDeleteBusinessOpportunity(id)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error?.response?.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doDeleteBusinessOpportunity(id)));
    } else {
      logging.error(error);
      yield put({
        type: DELETE_BUSINESS_OPPORTUNITY_FAILURE,
        error
      });
    }
  }
}

export function* watcherDeleteBusinessOpportunity() {
  yield takeLatest(DELETE_BUSINESS_OPPORTUNITY, workerDeleteBusinessOpportunity);
}

function* workerCreateBusinessOpportunity({ data, onUploadProgress }: CreateBusinessOpportunityAction) {
  try {
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<BusinessOpportunityModificationResponseData> = yield call(
        createBusinessOpportunity,
        {
          data,
          accessToken: profile.accessToken,
          onUploadProgress
        }
      );
      if (response.data) {
        yield put(doCreateBusinessOpportunitySuccess(response.data));
      } else {
        yield put(doCreateBusinessOpportunityFailure());
      }
    } else {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doCreateBusinessOpportunity(data)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error?.response?.status === 401) {
      yield call(workerRefreshTokenAndRetry, doRefreshTokenAndRetry(doCreateBusinessOpportunity(data)));
    } else {
      logging.error(error);
      yield put({
        type: CREATE_BUSINESS_OPPORTUNITY_FAILURE,
        error
      });
    }
  }
}

export function* watcherCreateBusinessOpportunity() {
  yield takeLatest(CREATE_BUSINESS_OPPORTUNITY, workerCreateBusinessOpportunity);
}

function* workerRetrieveBusinessOpportunities({ filter }: RetrieveBusinessOpportunitiesAction) {
  try {
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<BusinessOpportunitiesMMBrain>> = yield call(
        retrieveBusinessOpportunities,
        {
          ...filter,
          accessToken: profile.accessToken
        }
      );

      if (response.data && response.data['results']) {
        const oldBusinessOpportunitiess = yield select(
          (state: AppState) => state.businessOpportunityMMBrainReducer?.businessOpportunitiess
        );

        if (filter.pageNumber == 0) {
          yield put(
            doRetrieveBusinessOpportunitiesSuccess(
              response.data['results'],
              response.data['aggregations'],
              response.data['total']
            )
          );
        } else {
          yield put(
            //yield put(doRetrieveBusinessOpportunitiesStatsSuccess(response['stats']));
            doRetrieveBusinessOpportunitiesSuccess(
              //We don't want to just replace results - we add new results - used by infinitive scroll
              [...oldBusinessOpportunitiess, ...response.data['results']],
              response.data['aggregations'],
              response.data['total']
            )
          );
        }
      } else {
        yield put(doRetrieveBusinessOpportunitiesFailure());
      }
    } else {
      yield put(doRefreshTokenAndRetry(doRetrieveBusinessOpportunities(filter)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveBusinessOpportunities(filter)));
    } else {
      logging.error(error);
      const errorResult = parseError(error);
      yield put(doRetrieveBusinessOpportunitiesFailure(errorResult));
    }
  }
}

export function* watcherRetrieveBusinessOpportunities() {
  yield takeLatest(RETRIEVE_BUSINESS_OPPORTUNITIES, workerRetrieveBusinessOpportunities);
}

function* workerRetrieveManualBusinessOpportunities({ filter }: RetrieveManualBusinessOpportunitiesAction) {
  try {
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<BusinessOpportunitiesMMBrain>> = yield call(
        retrieveBusinessOpportunities,
        {
          internalSource: BoInternalSource.Manual,
          useElasticSearch: false,
          ...filter,
          accessToken: profile.accessToken
        }
      );

      if (response.data && response.data['results']) {
        yield put(doRetrieveManualBusinessOpportunitiesSuccess(response.data['results'], response.data['total']));
      } else {
        yield put(doRetrieveManualBusinessOpportunitiesFailure());
      }
    } else {
      yield put(doRefreshTokenAndRetry(doRetrieveManualBusinessOpportunities(filter)));
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(doRefreshTokenAndRetry(doRetrieveManualBusinessOpportunities(filter)));
    } else {
      logging.error(error);
      const errorResult = parseError(error);
      yield put(doRetrieveManualBusinessOpportunitiesFailure(errorResult));
    }
  }
}

export function* watcherRetrieveManualBusinessOpportunities() {
  yield takeLatest(RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES, workerRetrieveManualBusinessOpportunities);
}

//Counts
function* workerRetrieveBusinessOpportunitiesStats({
  keywords,
  countryGuids,
  businessTypeGuids,
  industryGuids,
  typesOfWorkGuids,
  isSustainable,
  internalSource
}: RetrieveBusinessOpportunitiesStatsAction) {
  try {
    const profile: AuthenticatedProfile | undefined = yield select((state: AppState) => state.authReducer.profile);

    if (profile) {
      const response: AxiosResponse<RequestResult<BusinessOpportunitiesMMBrain>> = yield call(
        retrieveBusinessOpportunitiesStats,
        {
          keywords,
          countryGuids,
          businessTypeGuids,
          industryGuids,
          typesOfWorkGuids,
          isSustainable,
          internalSource,
          accessToken: profile.accessToken
        }
      );

      if (response.data && response.data['aggregations']) {
        yield put(doRetrieveBusinessOpportunitiesStatsSuccess(response.data['aggregations']));
      } else {
        yield put(doRetrieveBusinessOpportunitiesStatsFailure());
      }
    } else {
      yield put(
        doRefreshTokenAndRetry(
          doRetrieveBusinessOpportunitiesStats(
            keywords,
            countryGuids,
            businessTypeGuids,
            industryGuids,
            typesOfWorkGuids,
            isSustainable,
            internalSource
          )
        )
      );
    }
  } catch (error) {
    if (error && error.response && error.response.status && error.response.status === 401) {
      yield put(
        doRefreshTokenAndRetry(
          doRetrieveBusinessOpportunitiesStats(
            keywords,
            countryGuids,
            businessTypeGuids,
            industryGuids,
            typesOfWorkGuids,
            isSustainable,
            internalSource
          )
        )
      );
    } else {
      logging.error(error);
      const errorResult = parseError(error);
      yield put(doRetrieveBusinessOpportunitiesStatsFailure(errorResult));
    }
  }
}

export function* watcherRetrieveBusinessOpportunitiesStats() {
  yield takeLatest(RETRIEVE_BUSINESS_OPPORTUNITIES_STATS, workerRetrieveBusinessOpportunitiesStats);
}

export function* businessOpportunitySaga() {
  yield all([
    call(watcherRetrieveBusinessOpportunities),
    call(watcherRetrieveManualBusinessOpportunities),
    call(watcherRetrieveBusinessOpportunitiesStats),
    call(watcherCreateBusinessOpportunity),
    call(watcherUpdateBusinessOpportunity),
    call(watcherDeleteBusinessOpportunity)
  ]);
}
