import {
  RETRIEVE_SUSTAINABILITY_STANDARDS,
  RETRIEVE_SUSTAINABILITY_STANDARDS_FAILURE,
  RETRIEVE_SUSTAINABILITY_STANDARDS_SUCCESS,
  RetrieveSustainabilityStandardsAction,
  RetrieveSustainabilityStandardsFailureAction,
  RetrieveSustainabilityStandardsSuccessAction
} from './types';
import { SustainabilityStandardMMBrain } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveSustainabilityStandards = (): RetrieveSustainabilityStandardsAction => ({
  type: RETRIEVE_SUSTAINABILITY_STANDARDS
});

export const doRetrieveSustainabilityStandardsFailure = (
  error?: RequestResult<void>
): RetrieveSustainabilityStandardsFailureAction => ({
  error,
  type: RETRIEVE_SUSTAINABILITY_STANDARDS_FAILURE
});

export const doRetrieveSustainabilityStandardsSuccess = (
  sustainabilityStandards: SustainabilityStandardMMBrain[]
): RetrieveSustainabilityStandardsSuccessAction => ({
  type: RETRIEVE_SUSTAINABILITY_STANDARDS_SUCCESS,
  sustainabilityStandards
});
