import axios from 'axios';
import getConfig from 'next/config';
import { SustainabilityStandardMMBrain } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function retrieveSustainabilityStandards(accessToken: string) {
  return axios.get<RequestResult<SustainabilityStandardMMBrain[]>>(
    `${publicRuntimeConfig.API_URI}/v1/en/mm-brain-api/sustainability-standards`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
}
