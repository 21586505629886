import { createReducer } from 'redux-starter-kit';
import { SustainabilityStandardMMBrain } from './interfaces';
import {
  RETRIEVE_SUSTAINABILITY_STANDARDS,
  RETRIEVE_SUSTAINABILITY_STANDARDS_FAILURE,
  RETRIEVE_SUSTAINABILITY_STANDARDS_SUCCESS,
  RetrieveSustainabilityStandardsSuccessAction,
  RetrieveSustainabilityStandardsFailureAction
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface SustainabilityStandardsReducerState {
  sustainabilityStandards?: SustainabilityStandardMMBrain[];
  loading: boolean;
  error: RequestResult<void> | null;
}

const initialState: SustainabilityStandardsReducerState = {
  sustainabilityStandards: [],
  loading: false,
  error: null
};

export default createReducer<SustainabilityStandardsReducerState>(initialState, {
  [RETRIEVE_SUSTAINABILITY_STANDARDS]: state => {
    state.loading = true;
    state.error = null;
  },
  [RETRIEVE_SUSTAINABILITY_STANDARDS_SUCCESS]: (state, action: RetrieveSustainabilityStandardsSuccessAction) => {
    state.loading = false;
    state.sustainabilityStandards = action.sustainabilityStandards;
    state.error = null;
  },
  [RETRIEVE_SUSTAINABILITY_STANDARDS_FAILURE]: (state, action: RetrieveSustainabilityStandardsFailureAction) => {
    state.loading = false;
    state.sustainabilityStandards = undefined;
    if (action.error) {
      state.error = action.error;
    }
  }
});
