import axios from 'axios';
import getConfig from 'next/config';
import { RequestResult } from 'src/interfaces/requests';
import {
  BusinessOpportunitiesMMBrain,
  BusinessOpportunityModificationData,
  BusinessOpportunityModificationResponseData,
  RetrieveBusinessOpportunitiesFilter
} from './interfaces';

const { publicRuntimeConfig } = getConfig();

const addArray2FormData = (
  fieldName: string,
  data: any,
  formData: FormData,
  stringify?: boolean,
  includeEmpty?: boolean
) => {
  if (data[fieldName]?.length) {
    for (let index = 0; index < data[fieldName].length; index++) {
      const item = data[fieldName][index];
      if (item) {
        formData.append(`${fieldName}[${index}]`, stringify ? JSON.stringify(item) : item);
      }
    }
  } else {
    if (includeEmpty) {
      formData.append(fieldName, '');
    }
  }
};

const businessOpportunityModificationData2FormData = (data: any, updateStatusOnly = false, update = false) => {
  const formData = new FormData();

  if (updateStatusOnly) {
    formData.append(`status`, data['status']);
    if (data['reason']) formData.append(`reason`, data['reason']);
    return formData;
  }

  if (data['resources']) {
    for (let index = 0; index < data['resources'].length; index++) {
      const file = data['resources'][index];
      formData.append(`resources`, file);
    }
  }

  const customProcessingFields = { resources: true, additionalContacts: true, attachments: true, createdBy: true };
  const plainArrayFields = ['types', 'industries', 'sectors', 'typesOfWork', 'sustainabilityStandards'];

  //Standard fields
  for (const key in data) {
    const value = data[key];
    if (customProcessingFields[key] !== true && value !== undefined && plainArrayFields.indexOf(key) === -1) {
      if (value === null) {
        formData.append(key, '');
      } else {
        formData.append(key, value);
      }
    }
  }

  //Plain arrays
  for (const plainArrayField of plainArrayFields) {
    addArray2FormData(plainArrayField, data, formData, undefined, update);
  }

  //Other custom fields
  if (data['additionalContacts']?.length) {
    for (let index = 0; index < 100; index++) {
      const item = data['additionalContacts'][index];
      if (item) {
        formData.append(`additionalContacts[${index}]`, JSON.stringify(item));
      }
    }
  } else {
    formData.append(`additionalContacts`, '');
  }

  if (data['createdBy']) {
    const item = data['createdBy'];
    if (item) {
      formData.append(`createdBy`, JSON.stringify(data['createdBy']));
    }
  }

  if (data['attachments']?.length) {
    for (let index = 0; index < 100; index++) {
      const item = data['attachments'][index];
      if (item) {
        formData.append(`attachments`, JSON.stringify(item));
      }
    }
  } else {
    formData.append(`attachments`, '');
  }

  return formData;
};

export function retrieveBusinessOpportunities(props: RetrieveBusinessOpportunitiesFilter & { accessToken?: string }) {
  const {
    accessToken,
    keywords,
    countryGuids,
    industryGuids,
    businessTypeGuids,
    typesOfWorkGuids,
    resultsPerPage = 10,
    pageNumber = 0,
    useElasticSearch = true,
    ...rest
  } = props;
  const body = {
    keyword: keywords,
    countries: countryGuids,
    industries: industryGuids,
    types: businessTypeGuids,
    typesOfWork: typesOfWorkGuids,
    limit: resultsPerPage || 10,
    offset: pageNumber * resultsPerPage,
    ...rest
  };

  const url = `${publicRuntimeConfig.API_URI}/v1/en/mm-brain-api/business-opportunities-${
    useElasticSearch ? 'elasticsearch' : 'search'
  }`;

  return axios.post<RequestResult<BusinessOpportunitiesMMBrain>>(url, body, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function createBusinessOpportunity(props: {
  data: BusinessOpportunityModificationData;
  accessToken?: string;
  onUploadProgress?: (progressEvent: any) => void;
}) {
  const formData = businessOpportunityModificationData2FormData(props.data);
  const url = `${publicRuntimeConfig.API_URI}/v1/en/mm-brain-api/business-opportunities`;
  return axios.post<RequestResult<BusinessOpportunityModificationResponseData>>(url, formData, {
    headers: { Authorization: `Bearer ${props.accessToken}`, 'Content-Type': 'multipart/form-data' },
    onUploadProgress: props.onUploadProgress
  });
}

export function updateBusinessOpportunity(props: {
  data: Partial<BusinessOpportunityModificationData>;
  accessToken?: string;
  id: string;
  updateStatusOnly?: boolean;
  onUploadProgress?: (progressEvent: any) => void;
}) {
  const formData = businessOpportunityModificationData2FormData(props.data, props.updateStatusOnly, true);
  const url = `${publicRuntimeConfig.API_URI}/v1/en/mm-brain-api/business-opportunities/${props.id}`;
  return axios.put<RequestResult<BusinessOpportunityModificationResponseData>>(url, formData, {
    headers: { Authorization: `Bearer ${props.accessToken}`, 'Content-Type': 'multipart/form-data' },
    onUploadProgress: props.onUploadProgress
  });
}

export function deleteBusinessOpportunity(props: { accessToken?: string; id: string; reason?: string }) {
  const url = `${publicRuntimeConfig.API_URI}/v1/en/mm-brain-api/business-opportunities/${props.id}`;
  return axios.delete<RequestResult<BusinessOpportunityModificationResponseData>>(url, {
    headers: { Authorization: `Bearer ${props.accessToken}` },
    data: {
      reason: props.reason
    }
  });
}

export function retrieveBusinessOpportunitiesStats(props: {
  keywords: string;
  countryGuids: string[];
  businessTypeGuids: string[];
  industryGuids: string[];
  typesOfWorkGuids: string[];
  isSustainable?: boolean;
  internalSource?: string;
  accessToken?: string;
}) {
  const body = {
    keyword: props.keywords,
    countries: props.countryGuids,
    industries: props.industryGuids,
    types: props.businessTypeGuids,
    typesOfWork: props.typesOfWorkGuids,
    isSustainable: props.isSustainable,
    internalSource: props.internalSource,
    limit: 1,
    offset: 0
  };

  const url = `${publicRuntimeConfig.API_URI}/v1/en/mm-brain-api/business-opportunities-elasticsearch`;

  return axios.post<RequestResult<BusinessOpportunitiesMMBrain>>(url, body, {
    headers: { Authorization: `Bearer ${props.accessToken}` }
  });
}
