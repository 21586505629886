import { createReducer } from 'redux-starter-kit';
import { SupsWatchlistDetails, SupsWatchlistPage, SupsWatchlistRaw } from './interfaces';
import {
  RETRIEVE_WATCHLISTS,
  RETRIEVE_WATCHLISTS_FAILURE,
  RETRIEVE_WATCHLISTS_SUCCESS,
  RETRIEVE_WATCHLISTS_PAGINATED,
  RETRIEVE_WATCHLISTS_PAGINATED_FAILURE,
  RETRIEVE_WATCHLISTS_PAGINATED_SUCCESS,
  RetrieveWatchlistsFailureAction,
  RetrieveWatchlistsSuccessAction,
  RetrieveWatchlistsPaginatedFailureAction,
  RetrieveWatchlistsPaginatedSuccessAction,
  CREATE_WATCHLIST,
  CREATE_WATCHLIST_SUCCESS,
  CREATE_WATCHLIST_FAILURE,
  UPDATE_WATCHLIST,
  UPDATE_WATCHLIST_SUCCESS,
  UPDATE_WATCHLIST_FAILURE,
  CreateWatchlistSuccessAction,
  CLEAR_WATCHLIST_MODIFICATIONS,
  RETRIEVE_WATCHLIST_DETAILS,
  RETRIEVE_WATCHLIST_DETAILS_SUCCESS,
  RETRIEVE_WATCHLIST_DETAILS_FAILURE,
  RetrieveWatchlistDetailsSuccessAction,
  RetrieveWatchlistDetailsFailureAction,
  UpdateWatchlistSuccessAction,
  DELETE_WATCHLIST,
  DELETE_WATCHLIST_SUCCESS,
  DELETE_WATCHLIST_FAILURE,
  DUPLICATE_WATCHLIST,
  DUPLICATE_WATCHLIST_SUCCESS,
  DUPLICATE_WATCHLIST_FAILURE,
  EXPORT_WATCHLIST,
  EXPORT_WATCHLIST_SUCCESS,
  EXPORT_WATCHLIST_FAILURE
} from './types';
import { RequestResult } from '../../../interfaces/requests';
import {
  REMOVE_WATCHLIST_COMPANIES_SUCCESS,
  REMOVE_WATCHLIST_COMPANY_SUCCESS,
  RemoveWatchlistCompaniesSuccessAction,
  RemoveWatchlistCompanySuccessAction,
  UPDATE_WATCHLIST_COMPANY_SUCCESS,
  UpdateWatchlistCompanySuccessAction
} from '@redux-modules/watchlistCompanies/types';
import {
  ADD_WATCHLIST_EDITOR_SUCCESS,
  AddWatchlistEditorSuccessAction,
  REMOVE_WATCHLIST_EDITOR_SUCCESS,
  RemoveWatchlistEditorSuccessAction
} from '@redux-modules/watchlistEditors/types';
import {
  ADD_WATCHLIST_COMMENT_SUCCESS,
  AddWatchlistCommentSuccessAction,
  REMOVE_WATCHLIST_COMMENT_SUCCESS,
  RemoveWatchlistCommentSuccessAction
} from '@redux-modules/watchlistComments/types';

export interface WatchlistsReducerState {
  pages?: SupsWatchlistPage[];
  total?: number;
  isPageLoading: boolean;
  pageError: RequestResult<void> | null;

  list?: SupsWatchlistRaw[];
  isListLoading: boolean;
  listError: RequestResult<void> | null;

  details?: SupsWatchlistDetails;
  isDetailsLoading: boolean;
  detailsError: RequestResult<void> | null;

  modifiedWatchlist?: SupsWatchlistRaw;
  isModificationLoading: boolean;
  isModificationSuccess?: boolean;
  modifiedWatchlistError: RequestResult<void> | null;
}

const initialState: WatchlistsReducerState = {
  pages: undefined,
  total: 0,
  isPageLoading: false,
  pageError: null,

  list: undefined,
  isListLoading: false,
  listError: null,

  details: undefined,
  isDetailsLoading: false,
  detailsError: null,

  modifiedWatchlist: undefined,
  isModificationLoading: false,
  isModificationSuccess: undefined,
  modifiedWatchlistError: null
};

const modifyHandler = (state: WatchlistsReducerState) => {
  state.isModificationLoading = true;
  state.isModificationSuccess = undefined;
  state.listError = null;
};

const modifySuccessHandler = <T extends { watchlist?: SupsWatchlistRaw }>(state: WatchlistsReducerState, action: T) => {
  state.isModificationLoading = false;
  state.modifiedWatchlist = action.watchlist;
  state.modifiedWatchlistError = null;
  state.isModificationSuccess = true;
};

const modifyErrorHandler = <T extends { error?: RequestResult<void> }>(state: WatchlistsReducerState, action: T) => {
  state.isModificationLoading = false;
  state.modifiedWatchlist = undefined;
  state.isModificationSuccess = false;
  if (action.error) {
    state.modifiedWatchlistError = action.error;
  }
};

export default createReducer<WatchlistsReducerState>(initialState, {
  [RETRIEVE_WATCHLISTS_PAGINATED]: state => {
    state.isPageLoading = true;
    state.pageError = null;
  },
  [RETRIEVE_WATCHLISTS_PAGINATED_SUCCESS]: (state, action: RetrieveWatchlistsPaginatedSuccessAction) => {
    state.isPageLoading = false;
    state.pages = action.watchlists;
    state.total = action.total;
    state.pageError = null;
  },
  [RETRIEVE_WATCHLISTS_PAGINATED_FAILURE]: (state, action: RetrieveWatchlistsPaginatedFailureAction) => {
    state.isPageLoading = false;
    state.pages = undefined;
    if (action.error) {
      state.pageError = action.error;
    }
  },
  [RETRIEVE_WATCHLISTS]: state => {
    state.isListLoading = true;
    state.listError = null;
  },
  [RETRIEVE_WATCHLISTS_SUCCESS]: (state, action: RetrieveWatchlistsSuccessAction) => {
    state.isListLoading = false;
    state.list = action.watchlists;
    state.listError = null;
  },
  [RETRIEVE_WATCHLISTS_FAILURE]: (state, action: RetrieveWatchlistsFailureAction) => {
    state.isListLoading = false;
    state.list = undefined;
    if (action.error) {
      state.listError = action.error;
    }
  },
  [CREATE_WATCHLIST]: modifyHandler,
  [CREATE_WATCHLIST_SUCCESS]: (state, action: CreateWatchlistSuccessAction) => {
    modifySuccessHandler(state, action);
    if (state.list && action.watchlist) {
      state.list = [action.watchlist, ...state.list];
    }
  },
  [CREATE_WATCHLIST_FAILURE]: modifyErrorHandler,
  [DUPLICATE_WATCHLIST]: modifyHandler,
  [DUPLICATE_WATCHLIST_SUCCESS]: modifySuccessHandler,
  [DUPLICATE_WATCHLIST_FAILURE]: modifyErrorHandler,
  [EXPORT_WATCHLIST]: modifyHandler,
  [EXPORT_WATCHLIST_SUCCESS]: modifySuccessHandler,
  [EXPORT_WATCHLIST_FAILURE]: modifyErrorHandler,
  [UPDATE_WATCHLIST]: modifyHandler,
  [UPDATE_WATCHLIST_SUCCESS]: (state, action: UpdateWatchlistSuccessAction) => {
    modifySuccessHandler(state, action);
    if (state.details && action.watchlist) {
      state.details = { ...state.details, ...action.watchlist };
    }
  },
  [UPDATE_WATCHLIST_FAILURE]: modifyErrorHandler,
  [REMOVE_WATCHLIST_COMPANY_SUCCESS]: (state, action: RemoveWatchlistCompanySuccessAction) => {
    if (state.details && action.company) {
      state.details = {
        ...state.details,
        companies: state.details.companies.filter(company => company.id !== action.company.id)
      };
    }
  },
  [REMOVE_WATCHLIST_COMPANIES_SUCCESS]: (state, action: RemoveWatchlistCompaniesSuccessAction) => {
    if (state.details && action.companies) {
      const deletedCompanyIds = action.companies.map(({ id }) => id);
      state.details = {
        ...state.details,
        companies: state.details.companies.filter(company => !deletedCompanyIds.includes(company.id))
      };
    }
  },
  [UPDATE_WATCHLIST_COMPANY_SUCCESS]: (state, action: UpdateWatchlistCompanySuccessAction) => {
    if (state.details && action.company) {
      state.details = {
        ...state.details,
        companies: state.details.companies?.map(company =>
          company.id === action.company.id ? { ...company, ...action.company } : company
        )
      };
    }
  },
  [ADD_WATCHLIST_EDITOR_SUCCESS]: (state, action: AddWatchlistEditorSuccessAction) => {
    if (state.details && action.editor) {
      state.details = {
        ...state.details,
        editors: [action.editor, ...state.details.editors]
      };
    }
  },
  [REMOVE_WATCHLIST_EDITOR_SUCCESS]: (state, action: RemoveWatchlistEditorSuccessAction) => {
    if (state.details && action.editor) {
      state.details = {
        ...state.details,
        editors: state.details.editors.filter(editor => editor.id !== action.editor.id)
      };
    }
  },
  [ADD_WATCHLIST_COMMENT_SUCCESS]: (state, action: AddWatchlistCommentSuccessAction) => {
    if (state.details && action.comment) {
      state.details = {
        ...state.details,
        comments: [action.comment, ...state.details.comments]
      };
    }
  },
  [REMOVE_WATCHLIST_COMMENT_SUCCESS]: (state, action: RemoveWatchlistCommentSuccessAction) => {
    if (state.details && action.comment) {
      state.details = {
        ...state.details,
        comments: state.details.comments.filter(editor => editor.id !== action.comment.id)
      };
    }
  },
  [DELETE_WATCHLIST]: modifyHandler,
  [DELETE_WATCHLIST_SUCCESS]: state => {
    state.isModificationLoading = false;
    state.modifiedWatchlistError = null;
    state.isModificationSuccess = true;
  },
  [DELETE_WATCHLIST_FAILURE]: modifyErrorHandler,
  [RETRIEVE_WATCHLIST_DETAILS]: state => {
    state.isDetailsLoading = true;
    state.detailsError = null;
  },
  [RETRIEVE_WATCHLIST_DETAILS_SUCCESS]: (state, action: RetrieveWatchlistDetailsSuccessAction) => {
    state.isDetailsLoading = false;
    state.details = action.watchlist;
    state.detailsError = null;
  },
  [RETRIEVE_WATCHLIST_DETAILS_FAILURE]: (state, action: RetrieveWatchlistDetailsFailureAction) => {
    state.isDetailsLoading = false;
    state.details = undefined;
    if (action.error) {
      state.detailsError = action.error;
    }
  },
  [CLEAR_WATCHLIST_MODIFICATIONS]: state => {
    state.isModificationSuccess = false;
    state.modifiedWatchlist = undefined;
    state.isModificationLoading = false;
    state.modifiedWatchlistError = null;
  }
});
