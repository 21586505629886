import { createReducer } from 'redux-starter-kit';
import { SupsWatchlistCompanyRaw } from './interfaces';
import {
  RETRIEVE_WATCHLIST_COMPANIES,
  RETRIEVE_WATCHLIST_COMPANIES_SUCCESS,
  RETRIEVE_WATCHLIST_COMPANIES_FAILURE,
  ADD_WATCHLIST_COMPANY,
  ADD_WATCHLIST_COMPANY_SUCCESS,
  ADD_WATCHLIST_COMPANY_FAILURE,
  RetrieveWatchlistCompaniesFailureAction,
  RetrieveWatchlistCompaniesSuccessAction,
  REMOVE_WATCHLIST_COMPANY,
  REMOVE_WATCHLIST_COMPANY_SUCCESS,
  REMOVE_WATCHLIST_COMPANY_FAILURE,
  REMOVE_WATCHLIST_COMPANIES,
  REMOVE_WATCHLIST_COMPANIES_SUCCESS,
  REMOVE_WATCHLIST_COMPANIES_FAILURE,
  CLEAR_WATCHLIST_COMPANY_MODIFICATIONS,
  UPDATE_WATCHLIST_COMPANY,
  UPDATE_WATCHLIST_COMPANY_SUCCESS,
  UPDATE_WATCHLIST_COMPANY_FAILURE
} from './types';
import { RequestResult } from '../../../interfaces/requests';

export interface WatchlistCompaniesReducerState {
  companies?: SupsWatchlistCompanyRaw[];
  isCompaniesLoading: boolean;
  companiesError: RequestResult<void> | null;

  modifiedWatchlistCompany?: SupsWatchlistCompanyRaw;
  isModificationLoading: boolean;
  isModificationSuccess?: boolean;
  modifiedWatchlistCompanyError: RequestResult<void> | null;
}

const initialState: WatchlistCompaniesReducerState = {
  companies: undefined,
  isCompaniesLoading: false,
  companiesError: null,

  modifiedWatchlistCompany: undefined,
  isModificationLoading: false,
  isModificationSuccess: undefined,
  modifiedWatchlistCompanyError: null
};

const modifyHandler = (state: WatchlistCompaniesReducerState) => {
  state.isModificationLoading = true;
  state.isModificationSuccess = undefined;
  state.modifiedWatchlistCompanyError = null;
};

const modifySuccessHandler = <T extends { company: SupsWatchlistCompanyRaw }>(
  state: WatchlistCompaniesReducerState,
  action: T
) => {
  state.isModificationLoading = false;
  state.modifiedWatchlistCompany = action.company;
  state.modifiedWatchlistCompanyError = null;
  state.isModificationSuccess = true;
};

const modifyErrorHandler = <T extends { error?: RequestResult<void> }>(
  state: WatchlistCompaniesReducerState,
  action: T
) => {
  state.isModificationLoading = false;
  state.modifiedWatchlistCompany = undefined;
  state.isModificationSuccess = false;
  if (action.error) {
    state.modifiedWatchlistCompanyError = action.error;
  }
};

export default createReducer<WatchlistCompaniesReducerState>(initialState, {
  [RETRIEVE_WATCHLIST_COMPANIES]: state => {
    state.isCompaniesLoading = true;
    state.companiesError = null;
  },
  [RETRIEVE_WATCHLIST_COMPANIES_SUCCESS]: (state, action: RetrieveWatchlistCompaniesSuccessAction) => {
    state.isCompaniesLoading = false;
    state.companies = action.watchlistCompanies;
    state.companiesError = null;
  },
  [RETRIEVE_WATCHLIST_COMPANIES_FAILURE]: (state, action: RetrieveWatchlistCompaniesFailureAction) => {
    state.isCompaniesLoading = false;
    state.companies = undefined;
    if (action.error) {
      state.companiesError = action.error;
    }
  },
  [ADD_WATCHLIST_COMPANY]: modifyHandler,
  [ADD_WATCHLIST_COMPANY_SUCCESS]: modifySuccessHandler,
  [ADD_WATCHLIST_COMPANY_FAILURE]: modifyErrorHandler,
  [UPDATE_WATCHLIST_COMPANY]: modifyHandler,
  [UPDATE_WATCHLIST_COMPANY_SUCCESS]: modifySuccessHandler,
  [UPDATE_WATCHLIST_COMPANY_FAILURE]: modifyErrorHandler,
  [REMOVE_WATCHLIST_COMPANY]: modifyHandler,
  [REMOVE_WATCHLIST_COMPANY_SUCCESS]: modifySuccessHandler,
  [REMOVE_WATCHLIST_COMPANY_FAILURE]: modifyErrorHandler,
  [REMOVE_WATCHLIST_COMPANIES]: modifyHandler,
  [REMOVE_WATCHLIST_COMPANIES_SUCCESS]: modifySuccessHandler,
  [REMOVE_WATCHLIST_COMPANIES_FAILURE]: modifyErrorHandler,
  [CLEAR_WATCHLIST_COMPANY_MODIFICATIONS]: state => {
    state.modifiedWatchlistCompany = undefined;
    state.isModificationLoading = false;
    state.isModificationSuccess = undefined;
    state.modifiedWatchlistCompanyError = null;
  }
});
