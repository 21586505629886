import {
  BusinessOpportunitiesResultsAggregationsMMBrain,
  BusinessOpportunityMMBrain,
  BusinessOpportunityModificationData,
  BusinessOpportunityModificationResponseData,
  BusinessOpportunityOperation,
  ManualBusinessOpportunityMMBrain,
  RetrieveBusinessOpportunitiesFilter
} from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_BUSINESS_OPPORTUNITIES = 'businessopportunities/RETRIEVE_BUSINESS_OPPORTUNITIES';
export const RETRIEVE_BUSINESS_OPPORTUNITIES_SUCCESS = 'businessopportunities/RETRIEVE_BUSINESS_OPPORTUNITIES_SUCCESS';
export const RETRIEVE_BUSINESS_OPPORTUNITIES_FAILURE = 'businessopportunities/RETRIEVE_BUSINESS_OPPORTUNITIES_FAILURE';

export const RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES = 'businessopportunities/RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES';
export const RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_SUCCESS =
  'businessopportunities/RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_SUCCESS';
export const RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_FAILURE =
  'businessopportunities/RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_FAILURE';

export const CREATE_BUSINESS_OPPORTUNITY = 'businessopportunities/CREATE_BUSINESS_OPPORTUNITY';
export const CREATE_BUSINESS_OPPORTUNITY_SUCCESS = 'businessopportunities/CREATE_BUSINESS_OPPORTUNITY_SUCCESS';
export const CREATE_BUSINESS_OPPORTUNITY_FAILURE = 'businessopportunities/CREATE_BUSINESS_OPPORTUNITY_FAILURE';

export const UPDATE_BUSINESS_OPPORTUNITY = 'businessopportunities/UPDATE_BUSINESS_OPPORTUNITY';
export const UPDATE_BUSINESS_OPPORTUNITY_SUCCESS = 'businessopportunities/UPDATE_BUSINESS_OPPORTUNITY_SUCCESS';
export const UPDATE_BUSINESS_OPPORTUNITY_FAILURE = 'businessopportunities/UPDATE_BUSINESS_OPPORTUNITY_FAILURE';

export const UPDATE_BUSINESS_OPPORTUNITY_NEW_PROGRESS =
  'businessopportunities/UPDATE_BUSINESS_OPPORTUNITY_NEW_PROGRESS';

export const DELETE_BUSINESS_OPPORTUNITY = 'businessopportunities/DELETE_BUSINESS_OPPORTUNITY';
export const DELETE_BUSINESS_OPPORTUNITY_SUCCESS = 'businessopportunities/DELETE_BUSINESS_OPPORTUNITY_SUCCESS';
export const DELETE_BUSINESS_OPPORTUNITY_FAILURE = 'businessopportunities/DELETE_BUSINESS_OPPORTUNITY_FAILURE';

export const DO_CLEAR_LAST_OPERATION_INFORMATION = 'businessopportunities/DO_CLEAR_LAST_OPERATION_INFORMATION';

export const RETRIEVE_BUSINESS_OPPORTUNITIES_STATS = 'businessopportunities/RETRIEVE_BUSINESS_OPPORTUNITIES_STATS';
export const RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_SUCCESS =
  'businessopportunities/RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_SUCCESS';
export const RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_FAILURE =
  'businessopportunities/RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_FAILURE';

export interface RetrieveBusinessOpportunitiesAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITIES;
  filter: RetrieveBusinessOpportunitiesFilter;
}

export interface RetrieveBusinessOpportunitiesSuccessAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITIES_SUCCESS;
  opportunities: BusinessOpportunityMMBrain[];
  aggregations: BusinessOpportunitiesResultsAggregationsMMBrain;
  total: number;
}

export interface RetrieveBusinessOpportunitiesFailureAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITIES_FAILURE;
  error?: RequestResult<void>;
}

export interface RetrieveManualBusinessOpportunitiesAction {
  type: typeof RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES;
  filter: RetrieveBusinessOpportunitiesFilter;
}

export interface RetrieveManualBusinessOpportunitiesSuccessAction {
  type: typeof RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_SUCCESS;
  opportunities: ManualBusinessOpportunityMMBrain[];
  total: number;
}

export interface RetrieveManualBusinessOpportunitiesFailureAction {
  type: typeof RETRIEVE_MANUAL_BUSINESS_OPPORTUNITIES_FAILURE;
  error?: RequestResult<void>;
}

//Stats
export interface RetrieveBusinessOpportunitiesStatsAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITIES_STATS;
  keywords: string;
  countryGuids: string[];
  businessTypeGuids: string[];
  industryGuids: string[];
  typesOfWorkGuids: string[];
  isSustainable?: boolean;
  internalSource?: string;
  accessToken?: string;
}

export interface RetrieveBusinessOpportunitiesStatsSuccessAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_SUCCESS;
  aggregations: BusinessOpportunitiesResultsAggregationsMMBrain;
}

export interface RetrieveBusinessOpportunitiesStatsFailureAction {
  type: typeof RETRIEVE_BUSINESS_OPPORTUNITIES_STATS_FAILURE;
  error?: RequestResult<void>;
}

// Create business opportunity
export interface CreateBusinessOpportunityAction {
  type: typeof CREATE_BUSINESS_OPPORTUNITY;
  data: BusinessOpportunityModificationData;
  onUploadProgress?: (uploadEvent: any) => void;
}

export interface CreateBusinessOpportunitySuccessAction {
  type: typeof CREATE_BUSINESS_OPPORTUNITY_SUCCESS;
  businessOpportunityModificationResponseData: BusinessOpportunityModificationResponseData;
}

export interface CreateBusinessOpportunityFailureAction {
  type: typeof CREATE_BUSINESS_OPPORTUNITY_FAILURE;
  error?: RequestResult<void>;
}

// Update business opportunity
export interface UpdateBusinessOpportunityAction {
  type: typeof UPDATE_BUSINESS_OPPORTUNITY;
  data: Partial<BusinessOpportunityModificationData>;
  id: string;
  operation?: BusinessOpportunityOperation;
  updateStatusOnly?: boolean;
  onUploadProgress?: (uploadEvent: any) => void;
}

export interface UpdateBusinessOpportunitySuccessAction {
  type: typeof UPDATE_BUSINESS_OPPORTUNITY_SUCCESS;
  businessOpportunityModificationResponseData: BusinessOpportunityModificationResponseData;
}

export interface UpdateBusinessOpportunityFailureAction {
  type: typeof UPDATE_BUSINESS_OPPORTUNITY_FAILURE;
  error?: RequestResult<void>;
}

export interface UpdateBusinessOpportunityNewProgressAction {
  type: typeof UPDATE_BUSINESS_OPPORTUNITY_NEW_PROGRESS;
  normalizedProgress?: number;
}

// Delete business opportunity
export interface DeleteBusinessOpportunityAction {
  type: typeof DELETE_BUSINESS_OPPORTUNITY;
  id: string;
  reason?: string;
}

export interface DeleteBusinessOpportunitySuccessAction {
  type: typeof DELETE_BUSINESS_OPPORTUNITY_SUCCESS;
}

export interface DeleteBusinessOpportunityFailureAction {
  type: typeof DELETE_BUSINESS_OPPORTUNITY_FAILURE;
  error?: RequestResult<void>;
}
